
import { defineComponent } from "vue";

export default defineComponent({
  name: "card",
  components: {},
  props: {
    cardClasses: String,

    avatar: String,

    online: Boolean,

    initials: String,

    color: String,

    name: String,

    position: String,

    avgEarnings: String,

    totalSales: String,

    connected: Boolean
  }
});
